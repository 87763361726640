import { deleteDataById, getDataBydId, getPaginatedData, insertData, patchData } from './commonService';

export default class ProjectService {


    #defaultUrl = "/api/Project";
    #defaultResourceName = "Proje";

    async getAllProjects() {
       return await getPaginatedData(this.#defaultUrl, this.#defaultResourceName);
    }

    async getProjectById(projectId) {
        return await getDataBydId(this.#defaultUrl, projectId, this.#defaultResourceName);
    }

    async insertProject(dataItem) {
        const createData = {
            "name": dataItem.name,
            "isActive": dataItem.isActive,
            "phone": dataItem.phone,
            "email": dataItem.email,
            "personName": dataItem.personName,
            "personPhone": dataItem.personPhone,
            "personEmail": dataItem.personEmail,
            "startDate": dataItem.startDate,
            "projectLogo": "ProjeLogo/LogoDefault.png"
        }
        return await insertData(this.#defaultUrl, createData, this.#defaultResourceName);
    }

    async deleteById(projectId) {
        return await deleteDataById(this.#defaultUrl, projectId, this.#defaultResourceName);
    }

    async updateProjectLogo(projectId, dataItem) {
        const patchDocument = [
            {"op": "replace", "path": "projectLogo", "value": dataItem.projectLogo},
        ];

        return await this.patchProject(projectId, patchDocument);
    }

    async updateProjectLocation(projectId, dataItem) {
        const patchDocument = [
            {"op": "replace", "path": "address", "value": dataItem.address},
            {"op": "replace", "path": "postalCode", "value": dataItem.postalCode},
            {"op": "replace", "path": "cityId", "value": dataItem.cityId},
            {"op": "replace", "path": "townId", "value": dataItem.townId},
        ];

        return await this.patchProject(projectId, patchDocument);
    }

    async updateProjectOfficial(projectId, dataItem) {
        const patchDocument = [
            {"op": "replace", "path": "taxNumber", "value": dataItem.taxNumber},
            {"op": "replace", "path": "taxOffice", "value": dataItem.taxOffice},
            {"op": "replace", "path": "officialName", "value": dataItem.officialName},
            {"op": "replace", "path": "officialAddress", "value": dataItem.officialAddress},
            {"op": "replace", "path": "officialCityId", "value": dataItem.officialCityId},
            {"op": "replace", "path": "officialTownId", "value": dataItem.officialTownId},
            {"op": "replace", "path": "officialPostalCode", "value": dataItem.officialPostalCode},
            {"op": "replace", "path": "eInvoiceAddress", "value": dataItem.eInvoiceAddress},
            {"op": "replace", "path": "bankName", "value": dataItem.bankName},
            {"op": "replace", "path": "iban", "value": dataItem.iban},
        ];

        return await this.patchProject(projectId, patchDocument);
    }



    async updateProject(projectId, dataItem) {
        const patchDocument = [
            {"op": "replace", "path": "name", "value": dataItem.name},
            {"op": "replace", "path": "isActive", "value": dataItem.isActive},
            {"op": "replace", "path": "phone", "value": dataItem.phone},
            {"op": "replace", "path": "personName", "value": dataItem.personName},
            {"op": "replace", "path": "email", "value": dataItem.email},
            {"op": "replace", "path": "personPhone", "value": dataItem.personPhone},
            {"op": "replace", "path": "personEmail", "value": dataItem.personEmail},
            {"op": "replace", "path": "startDate", "value": dataItem.startDate.valueOf()},
        ];

        if(dataItem.endDate != null && dataItem.endDate != '')
        {
            patchDocument.push(
                {"op": "replace", "path": "endDate", "value": dataItem.endDate.valueOf()}
            )
        }
        else {
            patchDocument.push(
                {"op": "replace", "path": "endDate", "value": null}
            )
        }

        return await this.patchProject(projectId, patchDocument);
    }
    async patchProject(id, patchDocument) {
        return await patchData(this.#defaultUrl, id, patchDocument, this.#defaultResourceName);
    }
}