import moment from 'moment';
import EmployeeService from '../../services/employeeService';
import { showErrorMessage, showValidationMessage } from '../../components/shared/toast/toastFunction';
import ProjectEmployeeService from '../../services/projectEmployeeService';
import ProjectService from '../../services/projectService';
import FirmService from '../../services/firmService';
import ManagerUserService from '../../services/managerUserService';
import CompanyService from '../../services/companyService';
import EmployeeSalaryService from '../../services/employeeSalaryService';


export async  function getInvoiceInputCategories() {
    return [
        {
            name: 'Temel Gıda',
            value: 'Temel Gıda',
        },
        {
            name: 'Kırmızı Et',
            value: 'Kırmızı Et',
        },
        {
            name: 'Beyaz Et',
            value: 'Beyaz Et',
        },
        {
            name: 'Sebze-Meyve',
            value: 'Sebze-Meyve',
        },
        {
            name: 'İçecek',
            value: 'İçecek',
        },
        {
            name: 'Ekmek',
            value: 'Ekmek',
        },
        {
            name: 'Sarf Malzemesi',
            value: 'Sarf Malzemesi',
        },
        {
            name: 'Merkez Depo',
            value: 'Merkez Depo',
        },
        {
            name: 'Personel',
            value: 'Personel',
        },
        {
            name: 'Personel Maaş',
            value: 'Personel Maaş',
        },
        {
            name: 'SGK',
            value: 'SGK',
        },
        {
            name: 'KDV',
            value: 'KDV',
        },
        {
            name: 'Tamirat Tadilat',
            value: 'Tamirat Tadilat',
        },
        {
            name: 'Kira ve Kiralama',
            value: 'Kira ve Kiralama',
        },
        {
            name: 'Demirbaş Giderleri',
            value: 'Demirbaş Giderleri',
        },
        {
            name: 'Enerji',
            value: 'Enerji',
        },
        {
            name: 'Pazarlama Reklam',
            value: 'Pazarlama Reklam',
        },
        {
            name: 'Vergi',
            value: 'Vergi',
        },
    ]
}

export async function getInvoiceOutputCategories() {
    return [
        {
            name: 'Satış',
            value: 'Satış',
        },
        {
            name: 'İade Faturası',
            value: 'İade Faturası',
        },
        {
            name: 'Fiyat Farkı',
            value: 'Fiyat Farkı',
        },
    ]
}
export async function getCompanyTypes() {
    return [
        {
            name: 'Tavuk',
            value: 'Tavuk',
        },
        {
            name: 'Et',
            value: 'Et',
        },
        {
            name: 'Su',
            value: 'Su',
        },
        {
            name: 'Temel Gıda',
            value: 'Temel Gıda',
        },
        {
            name: 'Sebze-Meyve',
            value: 'Sebze-Meyve',
        },
        {
            name: 'Tatlı',
            value: 'Tatlı',
        },
        {
            name: 'İşçilik',
            value: 'İşçilik',
        },
        {
            name: 'Ambalaj',
            value: 'Ambalaj',
        },
        {
            name: 'SGK',
            value: 'SGK',
        },
        {
            name: 'Yoğurt',
            value: 'Yoğurt',
        },
        {
            name: 'Ekmek',
            value: 'Ekmek',
        },
    ];
}

export async function getAllBanks() {
    return [
        {
            name: 'Banka Yok',
        },
        {
            name: 'Halk Bank',
        },
        {
            name: 'Vakıf Bank',
        },
        {
            name: 'Vakıf Katılım',
        },
        {
            name: 'Ziraat Bankası',
        },
        {
            name: 'Ziraat Katılım',
        },
        {
            name: 'Emlak Katılım',
        },
        {
            name: 'Kuveyt Türk',
        },
        {
            name: 'Albaraka Türk',
        },
        {
            name: 'Türkiye Finans',
        },
        {
            name: 'Akbank',
        },
        {
            name: 'İş Bankası',
        },
        {
            name: 'Yapı Kredi',
        },
        {
            name: 'Garanti Bankası',
        },
        {
            name: 'Deniz Bank',
        },
        {
            name: 'HSBC',
        },
        {
            name: 'ING',
        },
        {
            name: 'QNB Finansbank',
        },
        {
            name: 'TEB',
        },
        {
            name: 'Şekerbank',
        },
        {
            name: 'Fidabanka',
        },
        {
            name: 'Odeabank',
        },
        {
            name: 'Alternatif Bank',
        },        
    ];
}

export async function getForeignLanguageList() {
    return [
        {
            name: 'Seçim Yapın',
            value: '',
        },
        {
            name: 'İngilizce',
            value: 'İngilizce',
        },
        {
            name: 'Almanca',
            value: 'Almanca',
        },
        {
            name: 'Fransızca',
            value: 'Fransızca',
        },
        {
            name: 'İtalyanca',
            value: 'İtalyanca',
        },
        {
            name: 'İspanyolca',
            value: 'İspanyolca',
        },
        {
            name: 'Rusça',
            value: 'Rusça',
        },
        {
            name: 'Arapça',
            value: 'Arapça',
        },
        {
            name: 'Çince',
            value: 'Çince',
        },
    ];
}

export async function getMaritalStatusList() {
    return [
        {
            name: 'Evli',
            value: 'Evli',
        },
        {
            name: 'Bekar',
            value: 'Bekar',
        },
    ];
}

export async function getGenderList() {
    return [
        {
            name: 'Erkek',
            value: 'Erkek',
        },
        {
            name: 'Kadın',
            value: 'Kadın',
        },
    ];
}

export async function getMilitaryStatusList() {
    return [
        {
            name: 'Terhis',
            value: 'Terhis',
        },
        {
            name: 'Tecilli',
            value: 'Tecilli',
        },
        {
            name: 'Kaçak',
            value: 'Kaçak',
        },
        {
            name: 'Bedelli',
            value: 'Bedelli',
        },
        {
            name: 'Yükümlü Değil',
            value: 'Yükümlü Değil',
        },
        {
            name: 'Askerliğe Elverişsiz Raporlu',
            value: 'Askerliğe Elverişsiz Raporlu',
        },
    ];
}

export async function getEducationList() {
    return [
        {
            name: 'İlkokul',
            value: 'İlkokul',
        },
        {
            name: 'OrtaOkul',
            value: 'OrtaOkul',
        },
        {
            name: 'Lise',
            value: 'Lise',
        },
        {
            name: 'Meslek Lisesi',
            value: 'Meslek Lisesi',
        },
        {
            name: 'Yüksek Okul',
            value: 'Yüksek Okul',
        },
        {
            name: 'Üniversite',
            value: 'Üniversite',
        },
        {
            name: 'Yüksek Lisans',
            value: 'Yüksek Lisans',
        },
    ];
}

export async function getDepartmentList() {
    return [
        {
            name: 'Aşçı',
        },
        {
            name: 'Aşçı Yardımcısı',
        },
        {
            name: 'Bulaşıkçı',
        },
        {
            name: 'Mutfak',
        },
        {
            name: 'Mutfak Şefi',
        },
        {
            name: 'Kasiyer',
        },
        {
            name: 'Garson',
        },
        {
            name: 'Komi',
        },
        {
            name: 'Temizlik',
        },
        {
            name: 'Proje Müdürü',
        },
        {
            name: 'Muhasebe',
        },
        {
            name: 'Şoför',
        },
        {
            name: 'Gıda Mühendisi',
        },
        {
            name: 'Yönetici',
        }
    ];
}

export async function getEmployee(employeeId) {
    let employee = {};
    let employeeService = new EmployeeService();
    let getResponse = await employeeService.getEmployeeById(employeeId);
    if (getResponse.isSuccess) {
        employee.id = getResponse.data.id;
        employee.name = getResponse.data.name;
        employee.surName = getResponse.data.surName;
        employee.isActive = getResponse.data.isActive;
        employee.insertDate = getResponse.data.insertDate;
        employee.identityNumber = getResponse.data.identityNumber;
        if (getResponse.data.birthday != null && getResponse.data.birthday != '') {
            employee.birthday = moment(getResponse.data.birthday).toDate();
        }
        employee.gender = getResponse.data.gender;
        employee.education = getResponse.data.education;
        employee.lastSchool = getResponse.data.lastSchool;
        employee.militaryStatus = getResponse.data.militaryStatus;
        employee.maritalStatus = getResponse.data.maritalStatus;
        employee.foreignLanguage = getResponse.data.foreignLanguage;
        employee.childCount = getResponse.data.childCount;
        employee.address = getResponse.data.address;
        employee.cityId = getResponse.data.cityId;
        employee.townId = getResponse.data.townId;
        employee.townName = getResponse.data.townName;
        employee.cityName = getResponse.data.cityName;
        employee.gsm = getResponse.data.gsm;
        employee.email = getResponse.data.email;
        employee.emergencyPersonName = getResponse.data.emergencyPersonName;
        employee.emergencyPersonPhone = getResponse.data.emergencyPersonPhone;
        employee.employeePhoto = getResponse.data.employeePhoto;
        employee.isBanned = getResponse.data.isBanned;
        employee.banReason = getResponse.data.banReason;
        employee.activeProject = getResponse.data.activeProject;

        return employee;

    } else return {};
}

export async function getProject(projectId) {
    let projectService = new ProjectService();
    let getResponse = await projectService.getProjectById(projectId);
    if (getResponse.isSuccess) {
        let dataItem = {};
        dataItem.id = getResponse.data.id;
        dataItem.name = getResponse.data.name;
        if (dataItem.insertDate != null && dataItem.insertDate != '') {
            dataItem.insertDate = moment(dataItem.insertDate).toDate();
        }
        dataItem.isActive = getResponse.data.isActive;
        dataItem.phone = getResponse.data.phone;
        dataItem.email = getResponse.data.email;
        dataItem.personName = getResponse.data.personName;
        dataItem.personPhone = getResponse.data.personPhone;
        dataItem.personEmail = getResponse.data.personEmail;
        if (getResponse.data.startDate != null && getResponse.data.startDate != '') {
            dataItem.startDate = moment(getResponse.data.startDate).toDate();
        }
        if (getResponse.data.endDate != null && getResponse.data.endDate != '') {
            dataItem.endDate = moment(getResponse.data.endDate).toDate();
        }
        dataItem.projectLogo = getResponse.data.projectLogo;
        dataItem.address = getResponse.data.address;
        dataItem.postalCode = getResponse.data.postalCode;
        dataItem.cityId = getResponse.data.cityId;
        dataItem.townId = getResponse.data.townId;
        dataItem.cityName = getResponse.data.cityName;
        dataItem.townName = getResponse.data.townName;
        dataItem.taxNumber = getResponse.data.taxNumber;
        dataItem.taxOffice = getResponse.data.taxOffice;
        dataItem.officialAddress = getResponse.data.officialAddress;
        dataItem.officialName = getResponse.data.officialName;
        dataItem.officialCityId = getResponse.data.officialCityId;
        dataItem.officialTownId = getResponse.data.officialTownId;
        dataItem.officialPostalCode = getResponse.data.officialPostalCode;
        dataItem.bankName = getResponse.data.bankName;
        dataItem.iban = getResponse.data.iban;
        dataItem.eInvoiceAddress = getResponse.data.eInvoiceAddress;

        return dataItem;
    } else {
        return {};
    }
}

export async function getFirm(firmId) {
    let firmService = new FirmService();
    let dataItem = {};
    let getResponse = await firmService.getFirmById(firmId);
    if (getResponse.isSuccess) {
        dataItem.id = getResponse.data.id;
        dataItem.name = getResponse.data.name;
        dataItem.isActive = getResponse.data.isActive;
        dataItem.insertDate = getResponse.data.insertDate;
        dataItem.phone = getResponse.data.phone;
        dataItem.email = getResponse.data.email;
        dataItem.personName = getResponse.data.personName;
        dataItem.personPhone = getResponse.data.personPhone;
        dataItem.personEmail = getResponse.data.personEmail;
        dataItem.officialName = getResponse.data.officialName;
        dataItem.officialAddress = getResponse.data.officialAddress;
        dataItem.officialPostalCode = getResponse.data.officialPostalCode;
        dataItem.officialCityId = getResponse.data.officialCityId;
        dataItem.officialTownId = getResponse.data.officialTownId;
        dataItem.taxNumber = getResponse.data.taxNumber;
        dataItem.taxOffice = getResponse.data.taxOffice;
        dataItem.allowGsm = getResponse.data.allowGsm;
        dataItem.allowEmail = getResponse.data.allowEmail;
        dataItem.eInvoiceAddress = getResponse.data.eInvoiceAddress;
        dataItem.firmLogo = getResponse.data.firmLogo;
        dataItem.projectId = getResponse.data.projectId;
        dataItem.breakfastQuantity = getResponse.data.breakfastQuantity;
        dataItem.lunchQuantity = getResponse.data.lunchQuantity;
        dataItem.dinnerQuantity = getResponse.data.dinnerQuantity;
        dataItem.snackQuantity = getResponse.data.snackQuantity;
        dataItem.iftarQuantity = getResponse.data.iftarQuantity;
        dataItem.sahurQuantity = getResponse.data.sahurQuantity;
        dataItem.passiveReason = getResponse.data.passiveReason;
        dataItem.nightMealQuantity = getResponse.data.nightMealQuantity;
        if (getResponse.data.passiveDate != null && getResponse.data.passiveDate != '') {
            dataItem.passiveDate = moment(getResponse.data.passiveDate).toDate();
        }
        return dataItem;
    } else {
        return {};
    }
}

export async function getProjectEmployee(projectEmployeeId) {
    let projectEmployee = {};
    let _projectEmployeeService = new ProjectEmployeeService();
    let getResponse = await _projectEmployeeService.getProjectEmployeeById(projectEmployeeId);
    if (getResponse.isSuccess) {
        projectEmployee.id = getResponse.data.id;
        projectEmployee.projectId = getResponse.data.projectId;
        projectEmployee.employeeId = getResponse.data.employeeId;
        if (getResponse.data.startDate != null) {
            projectEmployee.startDate = moment(getResponse.data.startDate).toDate();
        }
        if (getResponse.data.endDate != null) {
            projectEmployee.endDate = moment(getResponse.data.endDate).toDate();
        }
        projectEmployee.isActive = getResponse.data.isActive;
        projectEmployee.officialSalary = getResponse.data.officialSalary;
        projectEmployee.unOfficialSalary = getResponse.data.unOfficialSalary;
        projectEmployee.insertDate = getResponse.data.insertDate;
        projectEmployee.iban = getResponse.data.iban;
        projectEmployee.department = getResponse.data.department;
        projectEmployee.passiveReason = getResponse.data.passiveReason;
        projectEmployee.comments = getResponse.data.comments;
        projectEmployee.insuranceDeposit = getResponse.data.insuranceDeposit;
        projectEmployee.bankName = getResponse.data.bankName;
        projectEmployee.isSalaryChanged = getResponse.data.isSalaryChanged;
        projectEmployee.isRetired = getResponse.data.isRetired;
    } else {
        showErrorMessage(this, getResponse.message);
    }
    return projectEmployee;
}

export async function getManagerUser(managerUserId) {
    let managerUserService = new ManagerUserService();
    let dataItem = {};
    let getResponse = await managerUserService.getManagerUserById(managerUserId);
    if (getResponse.isSuccess) {
        dataItem.id = getResponse.data.id;
        dataItem.name = getResponse.data.name;
        dataItem.surName = getResponse.data.surName;
        dataItem.email = getResponse.data.email;
        if(getResponse.data.insertDate != null && getResponse.data.insertDate != "")
        {
            dataItem.insertDate = moment(getResponse.data.insertDate).format("DD.MM.YYYY")
        }
        dataItem.isActive = getResponse.data.isActive;
        dataItem.refreshToken = getResponse.data.refreshToken;
        dataItem.refreshTokenExpireDate = getResponse.data.refreshTokenExpireDate;
        dataItem.userType = getResponse.data.userType;
        dataItem.lastLoginDate = getResponse.data.lastLoginDate;
        dataItem.passiveReason = getResponse.data.passiveReason;
        dataItem.gsmNumber = getResponse.data.gsmNumber;
        dataItem.profilePicture = getResponse.data.profilePicture;
        dataItem.canEditSalary = getResponse.data.canEditSalary;
        dataItem.menuState = getResponse.data.menuState;
        dataItem.password = '';
        if(getResponse.data.passiveDate != null && getResponse.data.passiveDate != "")
        {
            dataItem.passiveDate = moment(getResponse.data.passiveDate).format("DD.MM.YYYY")
        }
        return dataItem;
    } else {
        return  {};
    }
}

export async function getCompany(companyId) {
    let companyService = new CompanyService();
    let dataItem = {};
    let getResponse = await companyService.getCompanyById(companyId);
    if(getResponse.isSuccess) {
        dataItem.name = getResponse.data.name;
        dataItem.isActive = getResponse.data.isActive;
        dataItem.phone = getResponse.data.phone;
        dataItem.email = getResponse.data.email;
        dataItem.personName = getResponse.data.personName;
        dataItem.personPhone = getResponse.data.personPhone;
        dataItem.personEmail = getResponse.data.personEmail;
        dataItem.officialName = getResponse.data.officialName;
        dataItem.officialAddress = getResponse.data.officialAddress;
        dataItem.officialPostalCode = getResponse.data.officialPostalCode;
        dataItem.officialCityId = getResponse.data.officialCityId;
        dataItem.officialTownId = getResponse.data.officialTownId;
        dataItem.taxNumber = getResponse.data.taxNumber;
        dataItem.taxOffice = getResponse.data.taxOffice;
        dataItem.eInvoiceAddress = getResponse.data.eInvoiceAddress;
        dataItem.passiveReason = getResponse.data.passiveReason;
        if(getResponse.data.passiveDate != null && getResponse.data.passiveDate != "")
        {
            dataItem.passiveDate = moment(getResponse.data.passiveDate).format("DD.MM.YYYY")
        }
        dataItem.description = getResponse.data.description;
        dataItem.type = getResponse.data.type;

        return dataItem;
    }
    else {
        return {};
    }

}

export async function checkEmployeeSalaryCalculated(_this, checkDate, employeeProjectId){
    let month = moment(checkDate).toDate().getMonth() + 1;
    let year = moment(checkDate).toDate().getFullYear();
    let employeeSalaryService = new EmployeeSalaryService();
    let response = await employeeSalaryService.getSalaryByProjectEmployeeId(month, year, employeeProjectId);
    console.log(response);
    if(response.isSuccess)
    {
        if(response.data.data.length > 0)
        {
            showValidationMessage(_this, 'Bu Çalışan İçin '+ year + '-' + month +' ayı için Maaş Hesaplaması Yapılmış.');
            return true;
        }
        else {
            return false;
        }
    }
    return true;
}

export async function checkEmployeeStillActive(_this, projectEmployeeId)
{
    let projectEmployeeService = new ProjectEmployeeService();
    let response = await projectEmployeeService.getProjectEmployeeById(projectEmployeeId);
    if(response.isSuccess)
    {
        if(response.data.isActive == false)
        {
            showValidationMessage(_this, 'Bu Çalışan bu projede aktif değil.');
        }
        return response.data.isActive;
    }
    else
    {
        return false;
    }
}

export function checkEmployeeSalaryChanged(_this, dataItem)
{
    if(dataItem.isSalaryChanged == true)
    {
        showValidationMessage(_this, 'Son yapılan maaş değişikliği onaylanmadığı için maaşı etkileyecek bir işlem yapamazsınız');
        return false;
    }
    return true;
}

